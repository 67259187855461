import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

//core styles
import footerNavBarStyle from "assets/jss/material-dashboard-pro-react/components/footerNavBarStyle.jsx";

//Routes
import { withRouter } from "react-router-dom";

class FooterNavBar extends React.Component {
    // const [spacing, setSpacing] = React.useState(2);

    render() {
        const { classes } = this.props;

        const routePath = (path) => {
            this.props.history.push(path)
        }
        return (
            <div className={classes.gridContainer}>
                <Grid container>
                    <Grid item md={4} sm={12} xs={12} className={classes.firstGrid}>
                        <Link onClick={() => routePath("/auth/home")} className={classes.footerLeftMenu}>
                            Home
                        </Link>
                        {/* <Link href="/auth/about-page" className={classes.footerLeftMenu}>
                                About 
                            </Link> */}
                        <Link onClick={() => routePath("/auth/news-page")} className={classes.footerLeftMenu}>
                            News
                        </Link>
                        <Link href="mailto:admin@drughelp.care" className={classes.footerLeftMenu}>
                            Contact Us
                        </Link>
                        <Link onClick={() => routePath("/auth/privacy-policy-page")} className={classes.footerLeftMenu}>
                            Privacy Policy
                        </Link>
                        <Link onClick={() => routePath("/auth/Terms&Condition")} className={classes.footerLeftMenu}>
                            Terms and Conditions
                        </Link>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <div className={classes.copyrightdiv}>
                            <span role="img" aria-label="copyrights" >
                                Copyright ©️2020 drughelp.care, All rights reserved.
                            </span>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className={classes.lastGrid} >
                        <a href="https://www.facebook.com/Drughelp.careApp/" className={classes.socialmedia} target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook-f" ></i>
                        </a>
                        <a href="https://x.com/DrughelpC" target="_blank" rel="noopener noreferrer" className={classes.socialmedia}>
                            <i className={`${classes.iconsAlignment} fab fa-twitter`}/>
                        </a>
                        <a href="https://www.linkedin.com/company/drughelp-care" target="_blank" rel="noopener noreferrer" className={classes.socialmedia}>
                            <i className="fab fa-linkedin" ></i>
                        </a>
                    </Grid>
                </Grid>
            </div>
            // </div> 
        )
    }
}

FooterNavBar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(footerNavBarStyle)(withRouter(FooterNavBar));