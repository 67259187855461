import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  // var block = cx({
  //   [classes.block]: true,
  //   [classes.whiteColor]: white
  // });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        {/* <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="#home" className={block}>
                {rtlActive ? "الصفحة الرئيسية" : "Home"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={block}>
                {rtlActive ? "شركة" : "Company"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#portfolio" className={block}>
                {rtlActive ? "بعدسة" : "Portfolio"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#blog" className={block}>
                {rtlActive ? "مدونة" : "Blog"}
              </a>
            </ListItem>
          </List>
        </div> */}

        <p className={classes.right + " " + classes.rightColor}>
          &copy; {1900 + new Date().getYear()}{" "}
          <a href="https://drughelp.care" className={anchor + " " + classes.rightColor}>
            Drughelp.care
          </a>
        </p>
        <p className={classes.right}>
          <a href="https://x.com/DrughelpC" className={anchor}>
          <img alt="" src="https://img.icons8.com/color/24/000000/twitter.png"/>
          </a>
        </p>
        <p className={classes.right}>
          <a href="https://www.facebook.com/Drughelp.careApp/" className={anchor}>
          <img alt="" src="https://img.icons8.com/color/24/000000/facebook.png"></img>
          </a>
        </p>
        <p className={classes.right}>
          <a href="mailto:admin@drughelp.care" className={anchor + " " + classes.rightColor}>
            Contact us
          </a>
        </p>
        <p className={classes.right}>
          <a href="/auth/news-page" className={anchor + " " + classes.rightColor}>
            News
          </a>
        </p>
        <p className={classes.right}>
          <a href="/auth/about-page" className={anchor + " " + classes.rightColor}>
            About the site
          </a>
        </p>
        <p className={classes.right}>
          <a href="/auth/FAQs" className={anchor + " " + classes.rightColor}>
            Frequently Asked Questions
          </a>
        </p>
        <p className={classes.right}>
          <a href="/auth/privacy-policy-page" className={anchor + " " + classes.rightColor}>
            Privacy Policy
          </a>
        </p>
        <p className={classes.right}>
          <a href="/auth/search" className={anchor + " " + classes.rightColor}>

            Home1
          </a>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
