import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import api from "../../utils/api";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import WebIcon from "@material-ui/icons/Public";
import DirectionIcon from "@material-ui/icons/Directions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardAvatar from "components/Card/CardAvatar.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import PopupView from "./PopupView";
import AgencyIcon from "@material-ui/icons/AccountBalance";
import "../../assets/styles/fonts.css";
import "../../assets/styles/editDelete.css";
//"../../assets/styles/editDelete.css";

const styles = theme => ({

  progress: {
    margin: theme.spacing.unit * 2
  },
  logo: {
    borderRadius: "5px",
    borderColor: theme.palette.primary[700],
    borderWidth: "10px",
    marginTop: "-4em",
    marginRight: "15px",
    maxWidth: "350px",
    borderStyle: "outset",
    padding: "2px",
    marginLeft: "1em",
    float: "left"
  },
  imageProps: {
    width: '100px',
    height: '100px'
  }
});



class AgencyViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agencyFound: true,
      agencyLoading: true,
      agency: {},
      openPopup: false,
      deleteId: "",
      deleteName: ""
    };
  }
  getAgency = id => {
    api.get(
      "/agencies/" + id,
      data => {
        if (data.id) {
          this.setState({ ...data, agencyFound: true, agencyLoading: false, agency: data });
        } else {
          this.setState({ agencyFound: false, agencyLoading: false });
        }
      },
      error => {
        console.error(error);
        this.setState({ agencyFound: false, agencyLoading: false });
      }
    );
  };

  componentDidMount() {
    this.props.agencyID && this.getAgency(this.props.agencyID);
    // const { contact_persons = [] } = this.props.data || {};
    // console.log(contact_persons, this.props.data);
    // const temp = contact_persons.map(el => {
    //   return {
    //     value: el.id,
    //     label: el.full_name
    //   };
    // });
    // this.setState({ contact_persons: temp });
  }


  createEditServiceBtn = (drole, service, category) => {
    return <Link to={`/admin/edit-service/${service.id}&category=${category.id}`}><Button >Edit</Button></Link>;
  }

  componentWillMount() {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const agencyID = params.agencyID;
    this.getAgency(agencyID);

  }

  getConfirmPopUp = (message) => {
    return (
      <PopupView
        openPopup={this.state.openPopup}
        callbackFun={this.callbackFun}
        updateProps={this.updateProps}
        message={message}
        icon={<AgencyIcon />}
      />
    );
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  }

  callbackFun = () => {
    const { deleteId, deleteName, serviceType } = this.state;
    if (serviceType === "deleteAgency") {
      this.deleteAgencyService(deleteId, deleteName);
    } else if (serviceType === "duplicateAgency") {
      this.duplicateAgencyService(deleteId, deleteName);
    }
  }

  deleteAgencyService = (id, name) => {
    // if (!window.confirm("Are you sure you want to delete '" + name + "'")) {
    //   return false;
    // }
    //debugger;
    api.delete(`/services/${id}`, null, () => {

      //  this.searchUsers();
      this.props.enqueueSnackbar("Service deleted successfully", {
        variant: "success"
      });
      window.location.reload();
    }, () => {
      this.props.enqueueSnackbar("Unable to delete '" + name + "'", {
        variant: "error"
      });
    })
  }

  duplicateAgencyService = (id, name) => {
    // if (!window.confirm("Are you sure you want to duplicate '" + name + "'")) {
    //   return false;
    // }
    //debugger;
    api.delete(`/services/${id}/copy`, null, () => {
      //  this.searchUsers();
      window.location.reload();
      this.props.enqueueSnackbar("Service duplicatesd successfully", {
        variant: "success"
      });
      window.location.reload();
    }, () => {
      this.props.enqueueSnackbar("Unable to duplicate '" + name + "'", {
        variant: "error"
      });
    })
  }

  callDeletePopup = (id, name, flag) => {
    this.setState({
      deleteName: name,
      deleteId: id,
      openPopup: true,
      serviceType: flag
    });
  }

  addDeleteServiceBtn = (service) => {
    let role = sessionStorage.getItem("drughelp-role");
    if (role !== "admin" || typeof service === 'undefined') {
      return "";
    }
    else {
      return (
        <div className="edit-delete-container">
          <Button  style={{
            font: "14px",//Given Graphic Design: "normal normal normal 18px/22px Calibri",
            letterSpacing: "0px",
            color: "#233750",
            opacity: 1,
            }} 
            color="danger" round onClick={() => this.callDeletePopup(service.id, service.name, "deleteAgency")} >Delete Service</Button>
          <Button  style={{
                                  font: "14px",//Given Graphic Design: "normal normal normal 18px/22px Calibri",
                                  letterSpacing: "0px",
                                  color: "#233750",
                                  opacity: 1,
                                }}color="danger" round onClick={() => this.callDeletePopup(service.id, service.name, "duplicateAgency")} >Duplicate Service </Button>
        </div>)
    }
  }

  render() {
    // const drugrole = sessionStorage.getItem("drughelp-role");
    const { classes } = this.props;
    const {
      id,
      logo,
      name,
      description,
      lng,
      lat,
      phone_number,
      website,
      categories = [],
      agencyLoading = false,
      agencyFound = false,
      deleteName,
      serviceType,
      openPopup
    } = this.state;

    const baseURL = (process.env.NODE_ENV !== 'production') ? `http://localhost:8000/api/v1` : `/api/v1`;

    let message = "";
    if (serviceType === "deleteAgency") {
      message = "Are you sure you want to delete '" + deleteName + "' ?";
    } else if (serviceType === "duplicateAgency") {
      message = "Are you sure you want to duplicate '" + deleteName + "' ?";
    }

    return (
      <div>
        {agencyLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress className={classes.progress} color="secondary" />
            <p>Loading agency details</p>
          </div>
        )}
        {!agencyLoading && (
          <div>
            {!agencyFound && <Typography>Agency Not Found</Typography>}
            {agencyFound && (
              <Card profile>
                <CardAvatar>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img

                      src={`${baseURL}/assets/file/${logo}?r=${Date.now()}`}
                      className={classes.imageProps}
                      alt="..."
                    />
                  </a>
                </CardAvatar>
                <CardBody profile>
                  <h3 className={classes.cardTitle}>{name}</h3>
                  <h5 className={classes.cardCategory}>
                    <a href={website} target="_blank" rel="noopener noreferrer">
                      <Tooltip
                        title="Visit web site"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <WebIcon fontSize="large" />
                      </Tooltip>
                    </a>
                    <a
                      href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "1em" }}
                    >
                      <Tooltip
                        title="Get directions"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <DirectionIcon fontSize="large" />
                      </Tooltip>
                    </a>
                  </h5>
                  <Typography
                    variant="subtitle1"
                    inline={true}
                    className={classes.cardCategory}
                  >
                    Phone number:{" "}
                    <a
                      href={"tel:" + phone_number}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {phone_number}
                    </a>
                  </Typography>
                  <p
                    className={classes.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </CardBody>
              </Card>
            )}

            <Typography  gutterBottom={true} align="center" style={{
              fontFamily: "Nunito",
              fontSize : "30px",
              color : "#233750",
              fontWeight: "bold",
              lineHeight:"45pt"
            }}>
              SERVICES REGISTERED
            </Typography>

            {categories.map(category => {
              return (
                <ExpansionPanel key={category.id}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography style={{
                      font: "normal normal normal 18px/22px Calibri",
                      letterSpacing: "0px",
                      color: "#414042",
                      height: "19px"
                    }}>
                      {category.name} ({category.services.length})
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GridContainer className="service-container">
                      <GridItem xs={12}
                      style={{
                        font: "normal normal normal 18px/22px Calibri",
                        letterSpacing: "0px",
                        color: "#414042"
                      }}
                      >{category.description}</GridItem>
                      <GridItem xs={12} style={{ 
                        marginTop: "1em",
                        }}>
                        <Link
                          to={`/admin/edit-service?category=${category.id
                            }&agency=${id}`}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.marginRight}
                            style={{
                              borderRadius: "12px",
                              boxShadow: "0px 6px 6px #00000029",
                              width: "277px",
                              height: "54px",
                              font: "normal normal normal 18px/22px Calibri",
                              letterSpacing: "0px",
                              overflow: "visible"
                            }}
                          >
                            <AddIcon className={classes.icons} style={{
                                width: "auto",
                                height:"28px",
                              }}/>
                              <>{"  "}</>Add{" "}
                            {category.name}
                          </Button>

                        </Link>
                      </GridItem>
                      {category.services.map(service =>
                      {
                      return <GridItem xs={4} className="service-container-item">
                          <Card className={classes.card} style={{
                            //padding: "12px",
                            background : !service.active ? "rgba(65, 64, 66, 0.3)" : null,
                            boxShadow: "0px 3px 20px #00000029",
                            width: "427px",
                            height:"468px",
                          }}>
                            <CardContent style={{ padding : "25px"}}>
                              <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                                style={{
                                  font: "normal normal normal 18px/22px Calibri",
                                  letterSpacing: "0px",
                                  color:" #D9552C"
                                }}
                              >
                                {category.name}
                              </Typography>
                              <Typography variant="h5" component="h2"
                              style={{
                                textAlign: "left",
                                font: "normal normal bold 25px/30px Nunito",
                                letterSpacing: "0px",
                                color: "#233750",
                                opacity: 1,
                              }}
                              >
                                {service.name.toUpperCase()}
                                {!service.active ? 
                                <p style={{
                                  color : "#3D97D3",
                                  font: "normal normal bold 25px/34px Nunito",
                                  letterSpacing: "0px"
                                }}>
                                  (CLOSED)
                                </p>
                                :
                                null
                                }
                              </Typography>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              style={{
                                font: "normal normal normal 18px/22px Calibri",
                                letterSpacing: "0px",
                                color: "#D9552C"
                              }}
                              >
                                {service.address}
                              </Typography>
                              <Typography component="p" 
                              style={{
                                font: "normal normal normal 18px/22px Calibri",
                                letterSpacing: "0px",
                                color: "#414042"
                              }}
                              dangerouslySetInnerHTML={{ __html: service.description }}>
                              </Typography>
                            </CardContent>
                            <CardActions style={{
                              position : "absolute",
                              bottom: "0",
                              padding : "12px"
                            }}>

                              <Link to={`/admin/edit-service/${service.id}&category=${category.id}&agency=${id}`} style={{margin : "0px"}}>
                                <Button  style={{
                                  font: "14px",//Given Graphic Design: "normal normal normal 18px/22px Calibri",
                                  letterSpacing: "0px",
                                  color: "#233750",
                                  opacity: 1,
                                }}>
                                  Edit
                                </Button>
                              </Link>
                              {/* <Link to ={`/admin/edit-service/category=${category.id}&service=${service.id}`}><Button size="small">Edit</Button></Link> */}
                              {/* <Link
                          to={`/admin/edit-service?category=${
                            category.id
                          }&agency=${id}`}
                        > */}
                              <Link to={`/admin/view-slot?service=${service.id}&category=${category.id}`} style={{margin : "0px"}}>
                                <Button  style={{
                                  font: "14px",//Given Graphic Design: "normal normal normal 18px/22px Calibri",
                                  letterSpacing: "0px",
                                  color: "#233750",
                                  opacity: 1,
                                }}>
                                  Slot Information
                                </Button>
                              </Link>
                              {this.addDeleteServiceBtn(service)}
                            </CardActions>
                          </Card>
                        </GridItem>})
            }

                    </GridContainer>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
            <div>
              {openPopup ? this.getConfirmPopUp(message) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}


export default connect(null,
  // mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withSnackbar(AgencyViewPage)));
