import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCategory } from "actions/action_categories";
import { getServiceByCategory } from "actions/action_search_service";
import { getFeaturesByCategory } from "actions/action_features";
import { getPublicCategories } from "actions/action_categories";
import { searchAgenciesForMap } from "actions/action_agencies";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import {
  MenuItem,
  TextField,
  FormControl,
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  CircularProgress,
  CardActions,
  CardContent,
  Tooltip,
  Select,
  MenuList,
  Slide
} from "@material-ui/core";
import serviceSearchStyle from "assets/jss/material-dashboard-pro-react/views/serviceSearchStyle";
import ExpandMoreIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronLeftIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronRightIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronUpIcon from '@material-ui/icons/ExpandLess';
import check_icon from "assets/img/check_icon.png";
import SearchIcon from "@material-ui/icons/Search";
import Paginations from "components/Pagination/Pagination.jsx";
import { withSnackbar } from "notistack";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import ErrorIcon from "@material-ui/icons/Error";
import CallIcon from "@material-ui/icons/Call";
import WebIcon from "@material-ui/icons/Language";
import CardHeader from "components/Card/CardHeader.jsx";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import {
  formatPhoneNumber,
  getSlotInfo,
  getUpdatedAddress,
} from "utils/serviceUtils";
import ServicesMapView from "views/Components/ServicesMapView.jsx";
import getSortByOptions from "./serviceSearchUtils";
import ServiceSearchInfoWindow from "views/Pages/ServiceSearch/ServiceSearchInfoWindow.jsx";
import ZipCodePopUp from "../ZipCodePopUp.jsx";
import { Close, Link } from "@material-ui/icons";
import moment from "moment";
import { addLocaleData, IntlProvider } from "react-intl";
import messages from "../../../locale/index.js";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import { getStore } from "../../../store/configureStore";
import {changeLangSpanish} from "actions/action_lang";
import {changeLangEnglish} from "actions/action_lang";
import { flattenMessages } from "../../../utils/helper";
const store = getStore();
var printSwitch = true;
var openCloseFlag = false;
addLocaleData([...en, ...es]);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ServiceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.sortByOptions = getSortByOptions(this.props.match.params.id);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      filterData: [],
      search: "",
      timeInfoFilters: [],
      filterDataAns: [],
      timeInfoFilter: "",
      sortInfoFilters: this.sortByOptions[0].value,
      currentDay: new Date(),
      currentTime:
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds(),
      colorChanged: false,
      regex: /(<([^>]+)>)/gi,
      currentParamId: this.props.match.params.id,
      pathName: this.props.location.pathname,
      isServiceCalled: true,
      lat: "",
      lng: "",
      isUserAcceptedGeoLocation: false,
      geoLocationWarning: null,
      fetaureClicked: 0,
      tabValue: 0,
      zipCode: "",
      openZipCode: false,
      openDrawer: true,
      open: false,
      number: Number(localStorage.getItem('entered_number')) || 9,
      showServiceOnMap: null,
      openMobileViewFilter: false,
      closeMap: true,
    };
  }

  // intl = window.intl;

  componentDidMount() {
    const url = new URL(window.location.href);
    const filterValue = JSON.parse(url.searchParams.get('filter'));
    /*store.dispatch({
    type : "CHANGE_LANG_SPANISH"
    });*/
    if (filterValue) {
      this.setState(() => {
        return { filterDataAns: filterValue }
      }, () => {
        this.onLoadServiceSearch(this.state.currentParamId);
      })
    } else {
      this.onLoadServiceSearch(this.state.currentParamId);
    }
  }


  componentDidUpdate(prevProps, prevState) {
    /**  To filter service type passed in query (url)
     * used in chatbot
     */
    if (prevProps.changeLang.lang !== this.props.changeLang.lang) {
      // console.log("Language changed to:", this.props.changeLang.lang);
      this.searchServices();
    }
    if (
      this.props.location.state &&
      this.props.location.state.type &&
      this.props.location.state.type === "filter"
    ) {
      if (prevState.filterDataAns !== this.props.location.state.data) {
        this.setState({ filterDataAns: this.props.location.state.data }, () => {
          this.searchServices();
        });
      }
    }
    
  }

  componentWillMount() {
    this.getUserGeoLocation();
    this.unlisten = this.props.history.listen((location, action) => {
      var pathName = location.pathname;
      var id = pathName.split("/")[3];
      if (id) {
        this.props.categoryStore.data = {};
        this.setState(
          { currentParamId: id, isServiceCalled: true, pathName: pathName },
          () => {
            this.onLoadServiceSearch(this.state.currentParamId);
          }
        );
      }
    });
  }

  componentWillUnmount() {
    //this.unlisten();
    
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.unlisten();  
    }
    //this.unlisten();
  }

  renderMaps = () => {
    const { classes } = this.props;
    return (
      <>
        <GridItem xs={12} className={classes.mapRoot}>
          <ServicesMapView
            userLat={this.state.lat}
            userLng={this.state.lng}
            acceptedLocation={this.state.isUserAcceptedGeoLocation}
            agencies={this.props.agenciesForMap}
            //changeLang={this.state.langReducer}
            infowindow={<ServiceSearchInfoWindow />}
            parentComponent={"ServiceSearch"}
            showServiceOnMap={this.state.showServiceOnMap}
          />
        </GridItem>
      </>
    );
  };

  closeMaps = () => {
    const { closeMap } = this.state
    this.setState({ "closeMap": !closeMap });
  }

  // start - code related to Filters
  handleFilterChange = (question) => (e) => {
    const { id, question: name } = question;
    const { filterData = [] } = this.state;
    const newFilterData = e.target.checked
      ? [...filterData, { id, name }]
      : filterData.filter((q) => q.id !== id);
    this.setState({ filterData: newFilterData }, this.searchServices);
  };


  handleFilterChangeAns = (ans) => (c) => {
    let filterDataAns = this.state.filterDataAns || [];
    if (c.target.checked) {
      filterDataAns.push({ name: ans });
    } else {
      filterDataAns = filterDataAns.filter((c) => c.name !== ans);
    }

    this.setState({ filterDataAns }, () => {
      this.searchServices();
    });
  };

  setPrintSwitch = (que) => {
    printSwitch = false;
    return que.length > 35 ? que.substring(0, 30) + "..." : que;
  };

  isCheckboxSelected = (filterValue) => {
    const { filterDataAns } = this.state;
    const index = filterDataAns.findIndex(ele => {
      return ele['name'] && ele['name'] === filterValue ? true : false;
    })
    return index === -1 ? false : true;
  }

  renderServiceOptions = (categoryData) => {
    const { isCheckboxSelected } = this;
    const { classes, changeLang = {} } = this.props;
    const {lang = "en"} = changeLang;
    const c = categoryData.service_types;
    const answer_array = c || [{name:"loading"}]
    return (
      <IntlProvider locale={lang} messages={flattenMessages([messages["en"],messages[lang]])}>
      <ExpansionPanel style={{ border: "1px solid #d6d7d7" }}>
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon />
          }
        >
          <Typography>
            <b> 
              {window.intl.formatMessage({id : `view.filterYourResults.treatmentservices.${categoryData.name ? categoryData.name.split(" ").join("").toLowerCase() : "loading"}`})}
            </b>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormGroup>
            {answer_array.map((ans, i) => {
              ans = ans.name
              return <Tooltip
                key={i}
                title={window.intl.formatMessage({
                  id: `view.filterYourResults.treatmentservices.${this.setAttributeValues(ans).split(" ").join("").toLowerCase()}`,
                })}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
              >
                <FormControlLabel
                  key={i}
                  className={classes.questionName}
                  control={
                    <Checkbox
                      checked={isCheckboxSelected(ans)}
                      value={ans+""}
                      onChange={this.handleFilterChangeAns(ans)}
                      style={{ color: "#3d97d3" }}
                    />
                  }
                  label={ans === "Naloxbox Locations" ? "Naloxbox" : window.intl.formatMessage({id : `view.filterYourResults.treatmentservices.${this.setAttributeValues(ans).split("").filter(i => {return i!="/" && i!=" " && i!="(" && i!=")"}).join("").toLowerCase()}`})
                }
                />
              </Tooltip>
          })}
          </FormGroup>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </IntlProvider>
    );
  };

  renderFilters() {
    const { classes, categoryStore = {}, featuresStore = {}, changeLang } = this.props;
    var setFullWidth = {
      width: "100%",
    };
    const category = categoryStore.data || {};
    const features = featuresStore.data || {};
    const { filterData = [] } = this.state;
    const selectedQuestions = filterData.map((q) => q.id);
    let printSwitch = true;  // define printSwitch here
    const {lang = "en"} = changeLang;
    return (
      <Grid item sm={12} md={12} className={classes.filterGrid}>
        <Typography
          variant="title"
          gutterBottom
          className={classes.filtersHeading}
        >
          <span className={classes.disableInMobileView}>Filter Your Results</span>
          <button
            style={{
              borderWidth: 1,
              borderRadius: 5,
              backgroundColor: "white",
              color: "#1E1E1",
              padding: "5px",
              cursor: "pointer"
            }}
            onClick={() => this.clearAllResults()}
          >
            Clear All
          </button>
        </Typography>
        {this.renderServiceOptions(category, lang)}
        {features.map(
          (feature) =>{
            return feature.active && (
              <ExpansionPanel key={feature.id} square style={{ border: "1px solid #d6d7d7", borderTop: "none" }}>
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon />
                  }
                  aria-controls="panel1a-content"
                  id={"panel" + feature.id + "-header"}
                >
                  <Typography>
                    <b> 
                      {/*feature.name*/}
                      {window.intl.formatMessage({
                        id : `view.filterYourResults.${feature.name.split("").filter(i => {return i!='-' && i!='/' && i!=" " && i!="(" && i!=")"}).join("").toLowerCase()}.${feature.name.split("").filter(i => {return i!='-' && i!='/' && i!=" " && i!="(" && i!=")"}).join("").toLowerCase()}`
                      })}
                      </b>
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <MenuList>
                    {(feature.questions || [])
                      .filter((question) => question.active && question.type === "LINK")
                      .map((question, i) => {
                        return <Tooltip
                          key={i}
                          title={question.question}
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <MenuItem
                            style={setFullWidth}
                            onClick={() => window.open(question.hint, "_blank")}
                          >
                            <FormControlLabel
                              key={i}
                              className={classes.questionName}
                              control={
                                <Link
                                  style={{ color: "#3d97d3", paddingRight: "5px" }}
                                />
                              }
                              label={this.setPrintSwitch(question.question)}
                            />
                          </MenuItem>
                        </Tooltip>
                      })}
                  </MenuList>
                  <FormGroup>
                    {((printSwitch && feature.questions) || [])
                      .filter(
                        (question) =>
                          question.active && question.type === "SWITCH"
                      )
                      .map((question) => {
                        return <FormControlLabel
                          key={question.id}
                          className={classes.questionName}
                          control={
                            <Checkbox
                              value={question.id + ""}
                              checked={selectedQuestions.includes(question.id)}
                              onChange={this.handleFilterChange(question)}
                              style={{ color: "#3d97d3" }}
                            />
                          }
                          label={
                            <Typography
                              style={{ fontFamily: "Nunito", color: "#233750" }}
                            >
                              {/*question.question*/}
                              {window.intl.formatMessage({
                                id : 
                                  `view.filterYourResults.${feature.name.split("").filter(i => !["-","/"," ","(",")","&",",","."].includes(i)).join("").toLowerCase()}.${question.question.split("").filter(i => !["-","/"," ","(",")","&",",","."].includes(i)).join("").toLowerCase()}`})
                              }
                            </Typography>
                          }
                        />
                      })}
                  </FormGroup>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
  })}
      </Grid>
    );
  }
  // end - code related to Filters

  clearAllResults = () => {
    this.setState({ filterData: [], filterDataAns: [] }, this.searchServices);
  };

  renderSortByOptions() {
    const { classes, changeLang = {} } = this.props;
    const { lang = "en" } = changeLang;
    const { sortInfoFilters = "" } = this.state;
    
    return (
      <>
        <Grid container style={{ display: "flex" }}>
          <Grid item sm={4} md={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography
              component="div"
              style={{
                fontWeight: 600,//"normal",
                fontSize: "15px",
                fontFamily: 'Nunito',//"Helvetica",
                alignItems: "center",
                color: "#1E1E1",
              }}
            >
              <Grid item> Sort By:</Grid>
            </Typography>
          </Grid>
          <Grid item sm={8} md={8}>
            <FormControl className={classes.formControl}>
              <TextField
                select
                value={sortInfoFilters}
                onChange={this.handleSortInfoChange}
                fullWidth
                name="sortInfoFilters"
                id="sortInfoFilters"
                required
                InputProps={{
                  style: {
                    border: "1px solid grey",
                    borderBottom: "none !important",
                    paddingLeft: "10px",
                    borderWidth: 1,
                    borderRadius: 10,
                    backgroundColor: "white",
                    color: "#1E1E1",
                    padding: "1px",
                  },
                }}
              >
                {this.sortByOptions.map((option) => {
                  return <MenuItem value={option.value} key={option.value} style={{ borderBottom: "none !important" }}>
                    <em style={{ fontStyle: "normal", fontSize: "15px", fontFamily: 'Nunito'/*"Helvetica"*/, marginLeft: "10px" }}>{option.name.replace('(', ' (')}</em>
                  </MenuItem>
                })}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  }

  getUserGeoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            isUserAcceptedGeoLocation: true,
          });
          this.componentDidMount();
        },
        (error) => {
          var errMsg = error.message ? error.message : "";
          errMsg = errMsg.replace(/\s/g, "").toUpperCase();
          if (errMsg == "USERDENIEDGEOLOCATION") {
            this.setState({
              lat: "",
              lng: "",
              isUserAcceptedGeoLocation: false,
            });
          }
        }
      );
    } else {
      this.setState({
        lat: "",
        lng: "",
        isUserAcceptedGeoLocation: false,
      });
    }
  }

  onLoadServiceSearch(id) {
    this.setState({ currentParamId: id });
    this.props.getCategory(id);
    this.props.getPublicCategories({});
    this.props.getFeaturesByCategory(id);
    this.searchServices();
  }
  // Excel Sheet download open //

  getExcel = function () {
    const {
      searchStore: { servicesInfo = {} },
    } = this.props;
    const { result = {} } = servicesInfo;
    const { data = [] } = result;

    if (!data || data.length === 0) {
      return;
    }

    const requiredData = data.map((row) => ({
      AgencyName: row.agency.name,
      ServiceName: row.name,
      StreetAddress: row.address,
      City: row.city,
      State: row.state,
      ZipCode: row.zip,
      PhoneNumber: row.phone_number,
      website: row.website,
      Description: this.truncateDesc(row.description),
    }));

    const csvData = this.objectToCsv(requiredData);
    this.download(csvData);
  };


  download = function (csvData) {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SearchService.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  objectToCsv = function (data) {
    const csvRow = [];
    const headers = Object.keys(data[0]);
    csvRow.push(headers.join(","));
    for (const row of data) {
      const values = headers.map((header) => {
        const escapedValues = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escapedValues}"`;
      });
      csvRow.push(values.join(","));
    }
    return csvRow.join("\n");
  };

  // Excel Sheet Download Close //

  defaultTimeInfo() {
    var time_id = 5;
    if (this.props.match.params.id == 3) {
      time_id = 4; //5 - service hours
    }
    if (this.props.match.params.id == 4) {
      time_id = 9;
    }
    if (this.props.match.params.id == 5) {
      time_id = 8;
    }
    this.setState({ timeInfoFilter: time_id }, () => {
      this.searchServices();
    });
  }

  handleTimeInfoFilterChange = (info) => (e) => {
    let timeInfoFilters = this.state.timeInfoFilters || [];

    if (e.target.checked) {
      timeInfoFilters.push({ id: info.id, name: info.name });
    } else {
      timeInfoFilters = timeInfoFilters.filter((q) => q.id !== info.id);
    }
    this.setState({ timeInfoFilters }, () => {
      this.searchServices();
    });
  };

  handleTimeInfoChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value }, () => {
      this.searchServices();
    });
  };

  //distance from you
  check = () => {
    var xhr = new XMLHttpRequest();
    var target =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCa952cWXECVQi36D1luAAcsaxuZuymIgI";
    var num = Math.round(Math.random() * 10000);

    xhr.open("HEAD", target + "?num=" + num, true);
    xhr.send();
    xhr.addEventListener("readystatechange", processRequest, false);

    function processRequest(e) {
      if (xhr.readyState == 4) {
        if (xhr.status !== 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  };
  getFromZipCode = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status == window.google.maps.GeocoderStatus.OK) {
        this.setState({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          isUserAcceptedGeoLocation: true,
        });
        this.componentDidMount();
      } else {
        this.setState({
          lat: "",
          lng: "",
          isUserAcceptedGeoLocation: false,
        });
      }
    });
  };

  getZipCodePopUp = () => {
    return (
      <ZipCodePopUp
        zipCode={this.state.zipCode}
        openZipCode={this.state.openZipCode}
        callbackFun={this.zipCodeCallback}
        updateProps={this.updateProps} />
    );
  };

  zipCodeCallback = () => {
    let { zipCode } = this.state;
    if (zipCode) {
      this.getFromZipCode(zipCode);
    }
  };

  updateProps = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSortInfoChange = (evt) => {
    const { name, value } = evt.target;
    if (value === "6") {
      this.getUserGeoLocation();
      this.setState({ [name]: value }, () => {
        this.searchServices();
      });
    }
    if (value === "3") {
      this.setState({ [name]: value }, () => {
        this.searchServices();
      });
    }
    if (value === "2") {
      this.setState({ [name]: value }, () => {
        this.searchServices();
      });
    }
    if (value === "7") {
      this.setState({
        [name]: value,
        openZipCode: true
      });
    }
    if (value === "8") {
      this.setState({
        [name]: value,
      });
    }
    // value === 1 is for Recently Updates
    if (value === "1") {
      this.setState({
        [name]: value,
      }, () => {
        this.searchServices();
      });
    }
  };

  searchServices = (page = 1,filter = 0) => {
    const filters = this.state.filterData.map((f) => f.id);
    const cat_desc = this.state.filterDataAns.map((f, i) => f.name);
    let obj = {
      limit: this.state.number,
      category: this.state.currentParamId,
      filters,
      q: this.state.search,
      page,
      cat_desc,
      time_infos: parseInt(this.state.timeInfoFilter),
      sort_infos: this.state.sortInfoFilters,
    };
    if (this.state.lat && this.state.lng) {
      obj.lat = this.state.lat;
      obj.lng = this.state.lng;
      this.setState({
        geoLocationWarning: null,
      });
    } else if (
      !this.isUserAcceptedGeoLocation &&
      this.state.sortInfoFilters == "6"
    ) {
      this.setState({
        geoLocationWarning: this.props.enqueueSnackbar(
          "Please 'allow' location to see 'Distance from you' feature.",
          {
            variant: "info",
          }
        ),
      });
      obj.lat = 41.4993;
      obj.lng = -81.6944;
    } else if (
      !this.isUserAcceptedGeoLocation &&
      this.state.sortInfoFilters == "7"
    ) {
      this.setState({
        geoLocationWarning: this.props.enqueueSnackbar(
          "Please 'allow' location to see 'Distance from you' feature.",
          {
            variant: "info",
          }
        ),
      });
    }
    if (this.state.tabValue === 0) {
      this.props.getServiceByCategory(obj, () => { }, () => { });
      this.props.searchAgenciesForMap(obj);
    }
    // tabValue = 1 means map is displayed
    else this.props.searchAgenciesForMap(obj);
  };

  handleSearchChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.searchServices();
    }, 500);
  };

  getPagination = () => {
    const {
      searchStore: { servicesInfo = {} },
    } = this.props;
    const { last_page = 1, current_page = 1 } = servicesInfo.result;
    if (last_page <= 1) {
      return null;
    }

    let pageLimit;
    if (window && window.screen && window.screen.width <= 480) {
      pageLimit = 5
    }

    let pages = [];
    let limit = pageLimit ? pageLimit : 9;
    let start = 1;

    let end = last_page;
    if (last_page > limit) {
      start = current_page - Math.floor(limit / 2);
      end = current_page + Math.floor(limit / 2);
      if (start <= 0) {
        start = 1;
        end = limit;
      }
      if (end > last_page) {
        end = last_page;
        start = end - limit;
      }
    }
    for (let i = start; i <= end; i++) {
      pages.push({
        onClick: this.gotoPage,
        text: i,
        active: current_page === i,
      });
    }

    pages.unshift({
      text: window.intl.formatMessage({ id: "prev" }),
      onClick: this.gotoPrevPage,
    });
    pages.push({
      text: window.intl.formatMessage({ id: "next" }),
      onClick: this.gotoNextPage,
    });

    pages.push({
      text: window.intl.formatMessage({ id: `${current_page} of ${last_page}` }),
    });

    return (
      <Paginations
        pages={pages}
        color="info"
        currentPage={current_page}
        lastPage={last_page}
        gotoEnteredPage={this.getEnteredPage}
      />
    );
  };

  gotoPage = (evt) => {
    this.searchServices(evt.target.innerText);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  };

  gotoNextPage = () => {
    const {
      searchStore: { servicesInfo = {} },
    } = this.props;
    const { last_page = 1, current_page = 1 } = servicesInfo.result;
    if (current_page < last_page) {
      this.searchServices(current_page + 1);
      let html = document.getElementsByTagName("html");
      let body = document.getElementsByTagName("body");
      if (body && body[0]) {
        body[0].scrollTop = 0;
      }
      if (html && html[0]) {
        html[0].scrollTop = 0;
      }
    }
  };

  getEnteredPage = (evt) => {
    this.searchServices(evt);
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  };

  gotoPrevPage = () => {
    const {
      searchStore: { servicesInfo = {} },
    } = this.props;
    const { current_page = 1 } = servicesInfo.result;
    if (current_page > 1) {
      this.searchServices(current_page - 1);
    }
    let html = document.getElementsByTagName("html");
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      body[0].scrollTop = 0;
    }
    if (html && html[0]) {
      html[0].scrollTop = 0;
    }
  };

  showAccredation = (accreditations) => {
    if (accreditations.length > 0 && accreditations[0] == ";") {
      accreditations = accreditations.replace(";", "");
    }
    accreditations = accreditations.trim();
    accreditations = accreditations.replace(
      new RegExp(String.fromCharCode(160), "g"),
      ""
    ); //&nbsp
    accreditations = accreditations.replace(new RegExp(";", "gi"), ", ");

    if (accreditations.length == 0) {
      return "";
    }

    return (
      <Typography style={{ color: "#233750", fontWeight: 700, fontSize: 12 }}>
        <img
          class="card-img-top"
          src={check_icon}
          alt="Accreditations"
          style={{ width: "1rem", height: "1rem" }}
        />{" "}
        {accreditations}
      </Typography>
    );
  };

  getSlotData = (slotInfo, updatedAt, service) => {

    let slotData = getSlotInfo(slotInfo, updatedAt, true);
    
    let slots, wait_time, last_updated_str,walk_in;
    const { classes } = this.props;

    if (!slotData || typeof slotData !== "object") {
      return "";
    }
    slots = slotData["slots"];
    wait_time = slotData["wait_time"];
    last_updated_str = slotData["last_updated_str"];
    walk_in = slotData["walk_in"]
    if (isNaN(slots) || isNaN(wait_time)) {
      return "";
    }

    if (wait_time === 1) {
      return (
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Grid style={{ height: "20px" }}>
            <span style={{ display: "flex", flexDirection: "row", margin: "0px !important" }}>
              Current wait time is about <span className={classes.circle} style={{ marginLeft: "5px" }}> 1 </span> day
            </span>
          </Grid>
          <Grid style={{ height: "25px" }}>
            <span>{last_updated_str}</span>
          </Grid>
        </Grid>
      );
    }

    else if (wait_time > 1) {
      return (
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Grid style={{ height: "20px" }}>
            <span style={{ display: "flex", flexDirection: "row", margin: "0px !important" }}> Current wait time is about <span className={classes.circle} style={{ marginLeft: "5px" }}> {wait_time} </span> days</span>
          </Grid>
          <Grid style={{ height: "25px" }}>
            <span>{last_updated_str}</span>
          </Grid>
        </Grid>
      );
    }

    else if (slots === 1) {
      return (
        <>
          <div className={classes.circle}> {slots} </div> slot is available{" "}
          {last_updated_str}
        </>
      );
    }
    else if (slots > 1) {
      return (
        <>
          <div className={classes.circle}> {slots} </div> slots are available{" "}
          {last_updated_str}
        </>
      );
    }

    else if (slots === 0 && wait_time === 0) {
      return (
        <>
          <div className={classes.circle}> 0 </div> slots are available{" "}
          {last_updated_str}
        </>
      );
    } else if (slots === 0) {
      return "";
    }
    else if (wait_time === 0) {
      return "";
    }
    else {
      return "";
    }
};

  truncateDesc = (description) => {
    if (description == null) return "";
    description = description.replace(/<[^>]+>/g, "");
    description = description.replace("&nbsp;", "");
    return description;
  };

  convertTime = (time) => {
    var splits = time.split(":");
    return parseInt(splits[0]) * 3600 + parseInt(splits[1]) * 60;
  };
  setServiceName = (serviceName) => {
    printSwitch = false;
    return serviceName.length > 70 ? serviceName.substring(0, 50) + "..." : serviceName;
  };
  setAddress = (address) => {
    var newAddress = "";
    if (address[1] == ",") {
      newAddress = address.substr(2);
      return newAddress;
    } else {
      return address;
    }
  };

  handleOpen = () => {
    this.setState({ open: true, disableToggle: true });
  };

  handleClose = () => {
    this.setState({ open: false, disableToggle: false });
  };

  handleMobileViewFilter = () => {
    const { openMobileViewFilter } = this.state
    this.setState({ "openMobileViewFilter": !openMobileViewFilter });
  };

  getServicesForRender = () => {
    const {
      categoryStore = {},
      searchStore: { servicesInfo = {} },
      classes,
      changeLang = {}
    } = this.props;
    const category = categoryStore.data || {};
    const { loading = false, result = {}, error = "" } = servicesInfo;
    const { data = [], total } = result;
    const { lang = "en" } = changeLang;
    if (loading) {
      return (
        <Grid item md={12} align="center">
          <Typography variant="subtitle1" gutterBottom align="center">
            Loading services in {lang == "en" ? "English" : "Spanish"}... Please wait...
          </Typography>
        </Grid>
      );
    }
    if (error) {
      return (
        <SnackbarContent
          message={window.intl.formatMessage({
            id: "view.agencies.searchError",
          })}
          color="danger"
        />
      );
    }
    return (
      <>
      <IntlProvider locale={this.props.changeLang.lang} messages={flattenMessages(messages[lang])}>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="stretch"
          spacing={8}
          className={classes.cardsContainer}
          changeLang = {this.props.changeLang.lang}
        >
          <Grid container direction="row" wrap="nowrap" style={{ marginBottom: "10px", marginTop:"10px" }}>
            <Grid item xs={12} sm={8} md={6}>
              <Typography gutterBottom align="left" style={{margin:"10px"}}>
                Showing <strong>{result.to}</strong> services of <strong>{total}</strong> available
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={6} className={classes.KeysGrid}>
              <div className={classes.KeysContainer} style={{marginRight:"24px"}}>
                <span>Items per page</span>
                <FormControl variant="outlined" style={{ textAlign: 'center', minWidth: '120px' }}>
                  <Select
                    value={this.state.number}
                    onChange={(event) => {
                      const newNumber = event.target.value;
                      localStorage.setItem('entered_number', newNumber);
                      this.setState({ number: newNumber }, () => {
                        this.searchServices();
                      });
                    }}
                    labelId="results-per-page-label"
                    label="Results per page"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      getContentAnchorEl: null,
                    }}
                    renderValue={(selected) => (
                      <div style={{ textAlign: 'center' }}>{selected}</div>
                    )}
                  >
                    {[3, 6, 9, 12, 15].map((number) => (
                      <MenuItem key={number} value={number}>
                        {number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span>
                  <i style={{ color: "#233750", marginRight: "5px" }} className="fas fa-circle" /> Open
                  <i
                    style={{ marginLeft: 15, color: "grey", marginRight: "5px" }}
                    className="fas fa-circle"
                  />{" "}
                  Closed
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className={classes.cardsDiv}
          >
            { 
            data
            .sort((a,b) => (b.slot_infos.length>0 ? b.slot_infos.walk_in : 0)  - (a.slot_infos.length>0 ? a.slot_infos.walk_in : 0))
            .map((service) => {
            let openCloseFlag = false;

              return (
                <Grid
                  item
                  md={4}
                  sm={12}
                  key={service.id}
                  className={classes.cardGrid}
                  style={{
                    margin: "1.5em 0",
                    padding: "10px !important",
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    paddingLeft : "5px",
                    paddingRight :"5px"
                  }}
                >
                  {service.active ? (
                    <Card
                      className={classes.cardContainer}
                      style={{
                        borderRadius: 11,
                        marginTop: 0,
                        marginBottom: 0,
                        display: "flex",
                        height:"270px"
                      }}
                      onClick={() => {
                        service.agency_name &&
                          this.setState({ showServiceOnMap: service.agency_name });
                      }}
                    >
                      {service.time_info_data.map((value, index) => {
                          let service_id = value.pivot.service_id; // Grab the service ID of the current service.
                          let walk_in = false;
                          for (let i of service.time_info_data){
                            if (
                              (i.pivot.service_id === service_id) &&
                              (i.pivot.time_info_id === 6) &&
                              (i.pivot.day === this.state.currentDay.getDay()) &&
                              (i.pivot.active === 1) &&
                              (this.convertTime(i.pivot.start) <
                              this.convertTime(this.state.currentTime)) &&
                              (this.convertTime(this.state.currentTime) <
                              this.convertTime(i.pivot.end))
                            ){
                              walk_in = true; // Short term solution.
                              break;
                            }else{
                              continue;
                            }
                          }
                      
                          if (
                          value.pivot.time_info_id === this.state.timeInfoFilter &&
                          value.pivot.day === this.state.currentDay.getDay()
                        ) {
                          if (
                            value.pivot.active === 1 &&
                            this.convertTime(value.pivot.start) <
                            this.convertTime(this.state.currentTime) &&
                            this.convertTime(this.state.currentTime) <
                            this.convertTime(value.pivot.end)
                          ) {
                            openCloseFlag = true;
                            return (
                              <>
                              <CardHeader
                                key={index} // add key prop here
                                style={{
                                  backgroundColor: "#233750",// " grey"
                                  color: "white",
                                  fontWeight: "bold",
                                  borderTopLeftRadius: 10,
                                  borderTopRightRadius: 10,
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  paddingLeft: "12px",
                                  opacity: service.unregistered ? 0.25 : 1,
                                  padding: "3px 3px 3px 12px",
                                  color: "white",
                                }}
                              >
                                <Typography
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  {/*(service.service_type || {}).name*/
                                    window.intl.formatMessage({
                                      id : `view.filterYourResults.treatmentservices.${service.service_type.name.split("").filter(i => {return i!='-' && i!='/' && i!=" " && i!="(" && i!=")"}).join("").toLowerCase()}`
                                    })
                                  }
                                </Typography>
                                
                              </CardHeader>
                              {
                              (walk_in && (service.slot_infos[0].walk_in)) ?
                              <CardHeader
                                key={index+300} // add key prop here
                                style={{
                                  backgroundColor: "rgb(61, 151, 211)",
                                  color: "white",
                                  fontWeight: "bold",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  paddingLeft: "12px",
                                  opacity: service.unregistered ? 0.25 : 1,
                                  padding: "3px 3px 3px 12px",
                                  color: "white",
                                }}
                              >
                                <Typography
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  {window.intl.formatMessage({ id: "view.services.walkInsWelcome" })} until {moment(value.pivot.end, ["HH:mm:ss"]).format("LT")} today!
                                </Typography>
                                </CardHeader>
                                :
                                null}
                              </>
                            );
                          } else {
                            openCloseFlag = true;
                            return (
                              <>
                              <CardHeader
                                key={index+200} // add key prop here
                                style={{
                                  backgroundColor: "grey",
                                  color: "white",
                                  fontWeight: "bold",
                                  borderTopLeftRadius: 10,
                                  borderTopRightRadius: 10,
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  paddingLeft: "12px",
                                }}
                              >
                                <Typography
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  {/*(service.service_type || {}).name*/
                                    window.intl.formatMessage({
                                      id : `view.filterYourResults.treatmentservices.${service.service_type.name.split("").filter(i => {return i!='-' && i!='/' && i!=" " && i!="(" && i!=")"}).join("").toLowerCase()}`
                                    })
                                  }
                                </Typography>
                              </CardHeader>
                              {/*(walk_in && (service.slot_infos[0].walk_in))  ?
                              <CardHeader
                                key={index+100} // add key prop here
                                style={{
                                  backgroundColor: "rgb(61, 151, 211)",
                                  color: "white",
                                  fontWeight: "bold",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  paddingLeft: "12px",
                                  opacity: service.unregistered ? 0.25 : 1,
                                  padding: "3px 3px 3px 12px",
                                  color: "white",
                                }}
                              >
                                <Typography
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  {window.intl.formatMessage({ id: "view.services.walkInsWelcome" })} until {moment(value.pivot.end, ["HH:mm:ss"]).format("LT")} today!
                                </Typography>
                                </CardHeader>
                                :
                                null*/}
                              </>
                            );
                          }
                        }
                      })}

                      {openCloseFlag === false && (
                        <CardHeader
                          style={{
                            backgroundColor: "grey",
                            color: "white",
                            fontWeight: "bold",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            paddingLeft: "12px",
                          }}
                        >
                          <Typography style={{ color: "white", fontWeight: "bold" }}>
                            {(service.service_type || {}).name}
                          </Typography>
                        </CardHeader>    
                      )}
                      
                      <Grid container>
                        <Grid item xs={9} sm={9} md={12}>
                          <CardContent
                            style={{ marginBottom: "70px" }}
                            className={classes.cardContent}
                          >
                            <Typography
                              style={{
                                color: "#233750",
                                fontWeight: "normal",
                                fontSize: "13px",
                                //fontFamily: "Calibri",
                                display: "flex",
                                whiteSpace: "pre-line",
                                alignItems: "center",
                              }}
                            >
                              { 
                              (service.slot_infos.length > 0 ? service.slot_infos[0].walk_in : false) 
                              ? 
                              "" 
                              : 
                              this.getSlotData(service.slot_infos, service.updated_at, service) 
                              }
                            </Typography>
                            <Typography
                              variant="h5"
                              component="h2"
                              style={{
                                color: "#d9552c",
                                fontFamily: "Nunito",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {service.agency.name}
                            </Typography>
                            <Tooltip
                              title={service.name}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Typography
                                style={{
                                  color: "#233750",
                                  fontSize: "14px",
                                  fontFamily: "Nunito",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.setServiceName(service.name)}
                              </Typography>
                            </Tooltip>
                            {(() => {
                              var address = this.setAddress(service.address1);
                              if (address) {
                                return (
                                  <Typography
                                    className={classes.pos}
                                    style={{
                                      color: "#233750",
                                      fontWeight: "normal",
                                      fontSize: "15px",
                                      fontFamily: "Calibri",
                                    }}
                                  >
                                    {address}
                                    <br />
                                    {`${service.city}, ${getUpdatedAddress(service.state)} ${service.zip}`}
                                  </Typography>
                                );
                              }
                            })()}
                            <Typography
                              style={{
                                color: "#233750",
                                fontWeight: "bold",
                                fontSize: "14px",
                                fontFamily: "Calibri",
                              }}
                            >
                              {this.showAccredation((service.agency || {}).accreditations)}
                            </Typography>
                            {this.state.sortInfoFilters === "6" && (
                              <Typography
                                style={{
                                  color: "#808080",
                                  fontWeight: "normal",
                                  fontSize: 16,
                                  fontFamily: "Calibri",
                                  fontStyle: "Italic",
                                }}
                              >
                                {this.state.isUserAcceptedGeoLocation
                                  ? service.distance &&
                                  service.distance.toFixed(2) + " Miles away"
                                  : "Please allow location to view distance."}
                              </Typography>
                            )}
                            {this.state.sortInfoFilters === "7" && (
                              <Typography
                                style={{
                                  color: "#808080",
                                  fontWeight: "normal",
                                  fontSize: 16,
                                  fontFamily: "Calibri",
                                  fontStyle: "Italic",
                                }}
                              >
                                {this.state.isUserAcceptedGeoLocation
                                  ? service.distance &&
                                  service.distance.toFixed(2) + " Miles away"
                                  : "Please allow location to view distance."}
                              </Typography>
                            )}
                            {service.unregistered ? (
                              <Tooltip
                                title={window.intl.formatMessage({
                                  id: "view.services.unregisteredService",
                                })}
                                placement="bottom"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton className={classes.iconBtn}>
                                  <ErrorIcon fontSize="large" style={{ color: "#d0d1d3" }} />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </CardContent>
                        </Grid>
                        <Grid item xs={3} sm={3} md={12}>
                          <CardActions className={classes.attachToBottom + " " + classes.cardActions}>
                            <Tooltip
                              title={formatPhoneNumber(service.phone_number)}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                              style={{ fontFamili: "calibri" }}
                            >
                              <a href={`tel:${service.phone_number}`}>
                                <IconButton className={classes.iconBtn} color="primary">
                                  <CallIcon />
                                </IconButton>
                              </a>
                            </Tooltip>

                            <Tooltip
                              title={window.intl.formatMessage({
                                id: "view.services.openWebSite",
                              })}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <a
                                href={service.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <IconButton
                                  className={classes.iconBtn}
                                  color="primary"
                                  aria-label={window.intl.formatMessage({
                                    id: "view.services.openWebSite",
                                  })}
                                >
                                  <WebIcon />
                                </IconButton>
                              </a>
                            </Tooltip>
                            <a
                              href={`/auth/service-page/${service.id
                                }/${category.name &&
                                category.name.replace(/\s+/g, "-").toLowerCase()}/${category.id
                                }/${service.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()}/${service.agency.name
                                    .replace(/\s+/g, "-")
                                    .toLowerCase()}/${(service.service_type || {}).name
                                      .replace(/\s+/g, "-")
                                      .toLowerCase()}`}
                            >
                              <Button size="sm" color="primary">
                                {window.intl.formatMessage({
                                  id: "view.services.viewService",
                                })}
                              </Button>
                            </a>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </Card>
                  ) : (
                    // "Agency is not active so you cant view services"
                    ""
                  )}
                </Grid>
              )
            })}
          </Grid>
          <Grid item md={9} sm={12} align="center">
            {this.getPagination()}
          </Grid>
        </Grid>
        </IntlProvider>
      </>
    );
  };

  setAttributeValues = (attr) => {
    attr = attr && attr != "undefined" && attr != "null" ? attr : "view.defaultText.default";
    return attr;
  };

  renderSortAndSearchOptions = () => {
    const {
      classes,
      categoryStore = {},
      searchStore: {
        servicesInfo: { loading = false },
      },
      changeLang = {}
    } = this.props;
    const { search = '', timeInfoFilter = '' } = this.state;
    const category = categoryStore.data || {};
    const { lang = "en" } = changeLang;
    return (
      <>
      <IntlProvider locale={this.props.changeLang.lang} messages={flattenMessages(messages[lang])}>
        <Grid container className={classes.filterContainer}>
          <GridItem xs={12} sm={6} style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent : "center", alignItems:"center" }}>
            <>
              <input
                type="text"
                autoFocus
                value={search}
                id="search"
                onChange={this.handleSearchChange}
                placeholder={window.intl.formatMessage({id : "view.searchService.searchServices"})}
                style={{
                  width: '100%',
                  borderWidth: '1px',
                  borderRadius: 10,
                  padding: 10,
                  textColor: 'black',
                  height: '35px',
                  border: "1px solid #2f2f2f",
                  // borderColor : "red",
                  // borderRadius: "20px 0 0 20px",
                  // boxShadow: "0 2px 5px rgba(0, 0, 0, 1)"
                }}
              />
              <style>
                {`
                #search:focus{
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
                  }
                    
                #search:hover{
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
                  }
                `}
              </style>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: -30 }}>
                {loading ? (
                  <CircularProgress
                    size={25}
                    style={{ color: '#233750' }}
                  />
                ) : (
                  <SearchIcon />
                )}
              </div>
            </>
          </GridItem>
          <GridItem xs={12} sm={6} style={{ marginTop: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
            <div className={classes.filtersContainer}>{this.renderSortByOptions()}</div>
            <div className={classes.downloadDataDiv}>
              <button
                style={{
                  borderWidth: 1,
                  borderRadius: 10,
                  backgroundColor: '#233750',
                  color: 'white',
                  padding: '10px',
                  marginRight:"20px"
                }}
                className={classes.filterBtn}
                onClick={() => this.handleMobileViewFilter()}
              >
                Filter Your Results
              </button>
            </div>
            <div className={classes.downloadDataDiv}>
              <button
                variant="contained"
                onClick={(e) => {
                  this.getExcel();
                }}
                style={{
                  borderWidth: 1,
                  borderRadius: 10,
                  backgroundColor: 'white',
                  color: '#1E1E1',
                  padding: '10px',
                  cursor: "pointer"
                }}
              >
                Download Results
              </button>
            </div>
            {!this.state.closeMap && (
              <div>
                <button
                  variant="contained"
                  onClick={(e) => {
                    this.closeMaps();
                  }}
                  id="show-map-button"
                  style={{
                    borderWidth: 1,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    color: '#1E1E1',
                    padding: '10px',
                    cursor:"pointer",
                  }}
                >
                  Show Map
                </button>
              </div>
            )}
          </GridItem>
        </Grid>
        </IntlProvider>
      </>
    );
  };


  handleDrawerOpen = () => {
    const { openDrawer } = this.state
    this.setState({ "openDrawer": !openDrawer });
  };

  render() {
    const { openDrawer } = this.state;
    const { classes, categoryStore = {} } = this.props;

    const category = categoryStore.data || {};

    if (category.time_infos) {
      var firstId =
        category.time_infos &&
        category.time_infos.filter((info) => {
          var nCap = info.name;
          var pnCap = this.state.pathName;
          nCap = nCap && nCap.toUpperCase();
          pnCap = pnCap && pnCap.toUpperCase();
          if (pnCap.indexOf("PEER") !== -1 || pnCap.indexOf("HARM") !== -1) {
            return nCap.indexOf("SERVICE") !== -1;
          } else if (pnCap.indexOf("TREATMENT") !== -1) {
            return (
              nCap.indexOf("IN-TAKE") !== -1 || nCap.indexOf("INTAKE") !== -1
            );
          }
          //  return this.state.currentParamId == info.category;
        })[0];
      if (firstId && firstId.id && this.state.isServiceCalled) {
        this.setState(
          { timeInfoFilter: firstId.id, isServiceCalled: false },
          () => {
            this.searchServices();
          }
        );
      }
    }

    return (
      <div
        ref="ss_Ref"
        style={{
          minHeight: "100%",
          width: "100%",
          maxWidth: "1790",
          margin: "auto",
        }}
      >
        <GridContainer
          justify="center"
          container
          style={{ zIndex: "10", margin: "0px" }}
        >
          <GridItem xs={12} sm={12} md={11} style={{ color: "text" }}>
            <Card style={{ backgroundColor: "#233750" }}>
              <CardBody style={{ maxWidth: "100%" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{ color: "white" }}
                >
                  {window.intl.formatMessage({
                    id : `view.categoryNames.${(category.name || " ").split(" ").join("").toLowerCase()}`
                  })}
                  
                </Typography>
              </CardBody>
            </Card>
            <Card>
              <Grid container direction="row">

                <Slide direction="right" in={openDrawer} mountOnEnter unmountOnExit>
                  <Grid item xs={12} sm={3} md={3} className={classes.filtersContainer}>
                    {this.renderFilters()}
                  </Grid>
                </Slide>

                <Grid item xs={12} sm={openDrawer ? 9 : 12} md={openDrawer ? 9 : 12}>
                  <Grid container direction="column" justify="flex-start">
                    <Grid item>
                      {this.state.closeMap ? <Grid className={classes.collapseIcon}>
                        {
                          openDrawer ?
                            <ChevronRightIcon onClick={this.handleDrawerOpen} style={{ color: "#f4f7fc", fontSize: "45px" }} />
                            :
                            <ChevronLeftIcon onClick={this.handleDrawerOpen} style={{ color: "#f4f7fc", fontSize: "45px" }} />
                        }
                      </Grid> : null}
                      <Grid style={{ position: "absolute", right: 15, backgroundColor: "#233750", zIndex: 1, borderRadius: "10px" }}>
                        {this.state.closeMap ?
                          <ChevronUpIcon style={{ color: '#f4f7fc', fontSize: '45px' }} onClick={this.closeMaps} />
                          :
                          null}
                      </Grid>
                      {this.state.closeMap ? this.renderMaps() : null}
                    </Grid>
                    <Grid item>{this.renderSortAndSearchOptions()}</Grid>
                    <Grid item>{this.getServicesForRender()}</Grid>
                    <Grid item>{this.state.openZipCode ? this.getZipCodePopUp() : null}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </GridItem>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth maxWidth="lg"
            className={classes.dialogContainer}
            BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.8 } }}
          >
            <DialogTitle disableTypography className={classes.dialogTitlee} id="alert-dialog-title">
              <Typography style={{ color: "white", fontWeight: "bold" }}>
              </Typography>
              <Card style={{ backgroundColor: "#233750" }}>
                <CardBody style={{ maxWidth: "100%" }}>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ color: "white" }}
                  >
                    {category.name}
                  </Typography>
                </CardBody>
              </Card>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText id="alert-dialog-description">
                <Grid item>{this.getServicesForRender()}</Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Filters for Mobile View */}
          <Dialog
            open={this.state.openMobileViewFilter}
            onClose={this.handleMobileViewFilter}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            BackdropProps={{ style: { backgroundColor: "#233750", opacity: 0.95 } }}
          >
            <Grid style={{
              display: "flex",
              padding: "10px",
              background: "#233750",
              color: "white"
            }}>
              <Grid item xs={10} sm={10} style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontWeight: "normal", fontSize: "20px" }}>Filter Your Search</span>
              </Grid>
              <Grid item xs={2} sm={10} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <Close
                  style={{ fontWeight: "normal", fontSize: "20px" }}
                  onClick={this.handleMobileViewFilter}
                />
              </Grid>
            </Grid>
            <DialogContent>
              {this.renderFilters()}
            </DialogContent>
          </Dialog>
          {/* Filters for Mobile View */}
        </GridContainer>
      </div>
    );
  }
}
ServiceSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, prp) => {
  return {
    categoryStore: state.category,
    featuresStore: state.features,
    searchStore: state.searchStore,
    agenciesForMap: state.agencies,
    changeLang : state.langReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategory: bindActionCreators(getCategory, dispatch),
    getServiceByCategory: bindActionCreators(getServiceByCategory, dispatch),
    getFeaturesByCategory: bindActionCreators(getFeaturesByCategory, dispatch),
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch),
    searchAgenciesForMap: bindActionCreators(searchAgenciesForMap, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(serviceSearchStyle)(withSnackbar(ServiceSearch)));
