import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BigPlayButton, PosterImage } from "video-react";
import "../../../../node_modules/video-react/dist/video-react.css";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { getPublicCategories } from "../../../actions/action_categories";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import drughelp_mat from "assets/videos/Drug_Help_MAT_v6.mp4";
import drughelp_howto from "assets/videos/Drughelp_care_How_To_Video_v1_small.mp4";
import poster_mat from "assets/img/poster_mat.png";
import poster_howto from "assets/img/poster_howto.png";
import Treatment_types from "assets/img/Treatment_types.png";
import DH_TreatmentTypesVideo from "assets/videos/DH_TreatmentTypes_v2FINAL.mp4";
import homePageStyle from "assets/jss/material-dashboard-pro-react/components/homePageStyle.jsx";
import QuickSearch from "../QuickSearch/QuickSearch.jsx";
import api from "utils/api";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import { flattenMessages } from "../../../utils/helper";
import messages from "../../../locale/index";
addLocaleData([...en, ...es]);

const source = {
  drughelp_mat: drughelp_mat,
  drughelp_howto: drughelp_howto,
  poster_mat: poster_mat,
  poster_howto: poster_howto,
  name: drughelp_mat,
  poster: poster_mat,
  poster_tt: Treatment_types,
  drughelp_tt: DH_TreatmentTypesVideo,
};

class HomePage extends React.Component {
  ShowTheLocationWithRouter = withRouter(HomePage);
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      source: source,
      btnTitle: "HOW TO GET STARTED",
      btnType: "howto",
      iconType: "mat",
      htactive: false,
      ttactive: false,
      matactive: false,
      // set initial playlist link
      activeVideo: "videoseries?;list=PL-uO8hvdoXkxBjBitZ92zRqWRsIOqNnpa&rel=0",
      hotlineCount: 0,
      show: true,
    };
    this.props.getPublicCategories({});
  }

  changeVideo(property) {
    const source = { ...this.state.source };
    const totalList = [
      "videoseries?;list=PL-uO8hvdoXkxBjBitZ92zRqWRsIOqNnpa&rel=0",
      "jDujqm4V5ds?rel=0",
      "t7A6y8s_a18?rel=0",
    ];

    let newState = {};

    switch (property) {
      case "howto":
        newState = {
          btnTitle: "Treatment Services Video",
          btnType: "tt",
          iconType: "howto",
          htactive: true,
          ttactive: false,
          matactive: false,
          activeVideo: totalList[1],
        };
        break;
      case "mat":
        newState = {
          btnTitle: "How TO GET STarted",
          btnType: "howto",
          htactive: false,
          ttactive: false,
          iconType: "mat",
          matactive: true,
          activeVideo: totalList[0],
        };
        break;
      case "tt":
        newState = {
          btnTitle: "MAT Video",
          btnType: "mat",
          htactive: false,
          ttactive: true,
          iconType: "tt",
          matactive: false,
          activeVideo: totalList[2],
        };
        break;
      default:
        newState = {
          btnTitle: "MAT",
          btnType: "mat",
          htactive: false,
          ttactive: false,
          matactive: true,
          iconType: "mat",
          activeVideo: totalList[0],
        };
        break;
    }

    this.setState({
      ...newState,
      source: {
        ...source,
        name: this.state.source[`drughelp_${property}`],
      },
    });
  }

  homePageNavRoute(evt, obj, filterObj = []) {
    if (obj && obj.id) {
      const serviceName = obj.name && obj.name.replace(/\s/g, "").toLowerCase();

      const basePath = `/auth/search-service/${obj.id}/${serviceName}`;
      const filterQuery = filterObj.length > 0 ? `?filter=${JSON.stringify(filterObj)}` : '';

      this.props.history.push(`${basePath}${filterQuery}`);
    }
  }

  homePageButtons() {
    const { classes,changeLang = {} } = this.props;
    const { lang = "en" } = changeLang;
    const handleAdvancedSearch = () => {
      this.props.history.push("/auth/search-service/3/treatmentservices");
    };
    const msgs = {...flattenMessages(messages["en"]),...flattenMessages(messages[lang])};
    console.log(msgs)
    return (
      <IntlProvider locale={lang} messages={msgs}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <QuickSearch className={classes.mainButton}/>
          <Typography variant="body1" className={classes.quickSearchText}>
            {window.intl.formatMessage({
              id : "view.mainPage.quickSearchSubHeading"
            })}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Button
            variant="contained"
            onClick={handleAdvancedSearch}
            color="primary"
            className={classes.mainButton}
          >
            {window.intl.formatMessage({
              id : "view.mainPage.advancedSearchHeading"
            })}
          </Button>
          <Typography variant="body1" className={classes.quickSearchText}>
            {window.intl.formatMessage({
              id : "view.mainPage.advancedSearchSubHeading"
            })}
          </Typography>
        </Grid>
      </Grid>
      </IntlProvider>
    );
  }


  componentDidMount() {
    // function to get total active hotlines
    this.getHotlineCount();
  }

  async getHotlineCount() {
    try {
      const res = await api.get(`getActiveHotlines`);
      this.setState({ hotlineCount: res });
    } catch (error) {
      console.log(error);
    }
  }

  renderVideo() {
    const { classes } = this.props;

    return (
      <Grid item className={classes.videoContainer}>
        <iframe
          className={classes.youTubeResponsive}
          src={`https://www.youtube.com/embed/${this.state.activeVideo
            }&loop=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          id="ytplayer"
          title="Embedded youtube"
          rel="0"
        />
      </Grid>
    );
  }

  renderVideoIcons() {
    const { classes } = this.props;

    return (
      <Grid item>
        <ul className={classes.videoIconsul}>
          {['mat', 'tt', 'howto'].map(iconType => (
            <li key={iconType} style={{ display: 'inline' }}>
              {this.state.iconType === iconType ? (
                <IconButton style={{ color: '#3c4858', fontSize: '18px' }}>
                  <FiberManualRecordIcon className={classes.videoSlider} />
                </IconButton>
              ) : (
                <IconButton
                  style={{ color: '#bdbdbd' }}
                  onClick={(e) => this.changeVideo(iconType)}
                >
                  <FiberManualRecordIcon className={classes.videoSlider} />
                </IconButton>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    );
  }

  renderNaloxoneButton(category) {
    const { classes } = this.props;

    if (category.name.indexOf("Harm") !== -1) {
      return (
        <Grid item xs={3} className={classes.subConatainer}>
          <Button
            onClick={(e) => {
              this.homePageNavRoute(e, category, [
                { name: "Naloxbox Locations" },
                { name: "Narcan (Naloxone)" },
              ]);
            }}
            variant="contained"
            className={classes.naloxoneBtn}
          >
            {window.intl.formatMessage({id : "view.otherResources.freenaloxonebutton"})}
          </Button>
          <p className={classes.listBottomText}>{window.intl.formatMessage({id : "view.otherResources.freenaloxone"})}</p>
        </Grid>
      );
    }
    return null;
  }

  renderCrisisHotlinesButton() {
    const hotlineCount = this.state.hotlineCount;
    const { classes } = this.props;

    return (
      <Grid item xs={3} className={classes.subConatainer}>
        <Button
          variant="contained"
          onClick={() => {
            this.props.history.push("/auth/hotlinecategories");
          }}
          className={classes.bottomBtn}
        >
          {window.intl.formatMessage({id : "view.otherResources.crisishotlinebutton"})}
        </Button>
        <p className={classes.listBottomText}>
          {hotlineCount === 0
            ? window.intl.formatMessage({id : "view.otherResources.crisishotlinedefault"})
            : window.intl.formatMessage({id : "view.otherResources.crisishotlinecount"}).replace("{count}",hotlineCount)
            }
        </p>
      </Grid>
    );
  }

  render() {
    const { classes, categories = { data: [] }, changeLang = {}} = this.props;
    const { lang = "en" } = changeLang;
    let categoriesData = [];
    if (categories.data.length > 0) {
      categoriesData = [...categories.data];
      categoriesData.forEach((category) => {
        if (category.name.indexOf("Treatment") !== -1) {
          category.userDescription =
            "Substance use treatment that matches your personal characteristics and needs.";
        } else if (category.name.indexOf("Harm") !== -1) {
          category.userDescription =
            "Resources to stay alive and improve personal and community health";
        } else if (category.name.indexOf("Peer") !== -1) {
          category.userDescription =
            "Services for those in recovery and their families";
        }
      });
    }

    let newCategoriesData = categoriesData.filter(
      (category) => category.name !== "Treatment Services"
    );

    return (
      <IntlProvider locale={lang} messages={flattenMessages(messages[lang])}>
        <div className={classes.parentContainer}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container className={classes.headerContainer}>
                <Grid item className={classes.headerTextContainer}>
                  <p className={classes.welcomeText}>
                    {window.intl.formatMessage({id : "welcome"})}
                  </p>
                  <p className={classes.findTreatmentText}>
                    {window.intl.formatMessage({id : "findthesubstanceusetreatmentyouneed"})}
                  </p>
                </Grid>
                <div className={classes.desktopViewButtons}>
                  {this.homePageButtons()}
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              item
              xs={12}
              sm={12}
              md={6}
              className={classes.rightContainer}
            >
              {this.renderVideo()}
              {this.renderVideoIcons()}
            </Grid>
            <div className={classes.mobileViewButtons}>
              {this.homePageButtons()}
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.mainSubContainer}
          >
            <Grid item xs={12} sm={12} md={12} className={classes.otherResource}>
              <p className={classes.otherResource}> 
                {
                  window.intl.formatMessage({id : "view.otherResources.otherresources"})
                } 
                </p>
            </Grid>
            {newCategoriesData.map((category, id) => (
              <Fragment key={category.id || id}>
                <Grid item xs={3} className={classes.subConatainer}>
                  <Button
                    onClick={(e) => {
                      this.homePageNavRoute(e, category);
                    }}
                    variant="contained"
                    className={classes.bottomBtn}
                  >
                    {
                    window.intl.formatMessage({
                      id : `view.otherResources.${category.name.split(" ").join("").toLowerCase() + "button"}`
                    })
                    }
                  </Button>
                  <p className={classes.listBottomText}>

                    {
                    window.intl.formatMessage({
                      id : `view.otherResources.${category.name.split(" ").join("").toLowerCase()}`
                    })
                    }
                  </p>
                </Grid>
                {category.name.indexOf("Harm") !== -1 && this.renderNaloxoneButton(category)}
              </Fragment>
            ))}
            {this.renderCrisisHotlinesButton()}
          </Grid>
        </div>
      </IntlProvider>
    );
  }
}

BigPlayButton.propTypes = {
  position: PropTypes.string,
};

PosterImage.propTypes = {
  poster: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    changeLang : state.langReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPublicCategories: bindActionCreators(getPublicCategories, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(homePageStyle)(HomePage));